import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(error: HttpErrorResponse): Observable<any> {
    if (error['status'] === 401) {
        console.log(error.error)
      if (
        error.error.code ===
        'NotOnboarded'
      ) {
        this.router.navigate(['/error']);
      } else {
        // if cookie expires, retry authentication
        this.authService.identify().then(() => {
          window.location.reload();
        });
      }
    }
    throw error;
  }
}
