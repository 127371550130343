import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core';
import { SharedModule } from '../shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { ChatsModule } from '@app/chats/chats.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PolicyEditorSettingsComponent } from './policy-editor-settings.component';
import { PolicyEditorRoutingModule } from './policy-editor-settings.routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { AvatarModule } from 'ngx-avatar';
import { CustomRulesComponent } from './settings/custom-rules.component';
// import { StudentsSettingsComponent } from './settings/students-settings/students-settings.component';
// import { StaffSettingsComponent } from './settings/staff-settings/staff-settings.component';
import { CustomPolicySettingsComponent } from './settings/custom-settings/custom-settings.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    PolicyEditorRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ChatsModule,
    MatCheckboxModule,
    MatRadioModule,
    AvatarModule,
  ],
  declarations: [
    PolicyEditorSettingsComponent,
    CustomRulesComponent,
    // StudentsSettingsComponent,
    // StaffSettingsComponent,
    CustomPolicySettingsComponent,
  ],
})
export class PolicyEditorModule {}
