<header class="sticky">
  <nav class="navbar navbar-expand navbar-light bg-light">
    <div
      id="navbar-menu"
      class="collapse navbar-collapse"
      [ngbCollapse]="menuHidden"
    >
      <div class="product-name"><h2 class="">AI Chat</h2></div>
      <div class="navbar-nav ml-auto">
        <li
          class="nav-item dropdown"
          isAuthorizedToView
          [featureName]="'app-selector'"
        >
          <app-selector></app-selector>
        </li>
        <!-- <app-language-selector
          inNavbar="true"
          menuClass="dropdown-menu dropdown-menu-right"
        ></app-language-selector> -->

        <div class="nav-item" ngbDropdown>
          <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
            <i class="icon icon-circle-08-2"></i>
            <i class="icon icon-down-arrow"></i>
          </a>
          <div
            ngbDropdownMenu
            aria-labelledby="user-dropdown"
            class="appSettingsDropdown dropdown-menu dropdown-menu-right"
          >
            <h6 class="dropdown-header">
              <img src="assets/scss/images/icon-16-px-user.png" />
              {{ email }}
            </h6>
            <div class="dropdown-divider"></div>
            <a
              isAuthorizedToView
              [featureName]="'user-guide'"
              href="https://docs.securly.com/docs/ai-chat"
              target="_blank"
              class="dropdown-item dropdown-value"
              ngbDropdownItem
            >
              <i class="icon icon-paper"></i>AI Chat User Guide
            </a>
            <a
              href="javascript:void(0)"
              class="dropdown-item dropdown-value"
              ngbDropdownItem
              (click)="$event.preventDefault(); onLogout($event)"
            >
              <i class="icon icon-logout-2"></i>Log Out
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
