import {
  createAccount,
  editAccount,
  getAccountId,
  loadAllAccounts,
  lookupAccounts,
} from './history.actions';

export interface State {
  quote: string;
  accounts: any;
  customer: any;
  newAccount: any;
  notification: any;
  modifiedAccount: any;
  totalCount: number;
  offset: number;
}

export const initialState: State = {
  quote: '',
  accounts: {},
  customer: {},
  newAccount: {},
  notification: {},
  modifiedAccount: {},
  totalCount: 0,
  offset: 0,
};

// tslint:disable-next-line: typedef
export function reducer(state = initialState, action: any): State {
  let stateToReturn = state; // default

  switch (action.type) {
    case loadAllAccounts.success.type:
      return Object.assign({}, state, {
        accounts: action.customers,
        totalCount: action.total,
        offset: action.offset,
      });
    case getAccountId.success.type:
      return Object.assign({}, state, {
        customer: action.customer,
      });
    case createAccount.success.type:
      return Object.assign({}, state, {
        newAccount: action.customer,
        notification: {
          type: 'CREATE_ACCOUNT_SUCCESS',
          data: {
            message: 'Account created Successfully',
          },
        },
      });
    case editAccount.success.type:
      return Object.assign({}, state, {
        modifiedAccount: action.customer,
        notification: {
          type: 'EDIT_ACCOUNT_SUCCESS',
          data: {
            message: 'Account modified Successfully',
          },
        },
      });
    case lookupAccounts.success.type:
      return Object.assign({}, state, {
        accounts: action.customers,
      });
    case createAccount.success.type:
      return Object.assign({}, state, {
        newAccount: action.customer,
        notification: {
          type: 'CREATE_ACCOUNT_SUCCESS',
          data: {
            message: 'Account created Successfully',
          },
        },
      });
    case lookupAccounts.success.type:
      return Object.assign({}, state, {
        accounts: action.customers,
      });
    default:
      stateToReturn = state;
      break;
  }
  return stateToReturn;
}

export const getAccounts = (state: State) => state.accounts;
export const getLookupAccounts = (state: State) => state.accounts;
export const getNewAccount = (state: State) => state.newAccount;
export const getModifiedAccount = (state: State) => state.modifiedAccount;
export const getAccountsNofication = (state: State) => state.notification;
export const getTotalCount = (state: State) => state.totalCount;
export const getOffset = (state: State) => state.offset;
export const getAccount = (state: State) => state.customer;
