import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { CoreService } from '@app/core/core.service';
import { Store } from '@ngrx/store';
import * as reducers from './reducers';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private store: Store<reducers.State>
  ) {}
}
