export const individualChathistory = [
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 1...',
  },
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 2...',
  },
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 3...',
  },
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 4...',
  },
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 5...',
  },
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 6...',
  },
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 7...',
  },
  {
    dateTime: 268133400,
    chats: 'Lorem ipsum 8...',
  },
];
