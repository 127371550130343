import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizationService } from '../../core/authorization.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[isAuthorizedToView]',
})
export class IsAuthorizedToViewDirective implements OnInit {
  @Input() featureName: string; //pass pathname to check canActivate route permission

  constructor(
    private elementRef: ElementRef,
    private authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
    if (
      this.authorizationService.userPermissions &&
      !this.authorizationService.hasPermission(this.featureName)
    ) {
      this.elementRef.nativeElement.style.display = 'none';
    } else {
      // Otherwise, must request permissions from the API first
      return new Promise<boolean>((resolve, reject) => {
        this.authorizationService
          .initializePermissions()
          .then(() => {
            if (!this.authorizationService.hasPermission(this.featureName)) {
              this.elementRef.nativeElement.style.display = 'none';
            }
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    }
  }
}
