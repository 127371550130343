import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, mergeMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class RedirectLogoutInterceptor implements HttpInterceptor {
  constructor(private router: Router, private cookieService: CookieService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      mergeMap((response: any) => {
        if (
          response &&
          response instanceof HttpResponse &&
          response.status === 302
        ) {
          this.clearAllCookies();
          this.router.navigate(['/classes/main/list/gridview/1']);
        }
        return of(response);
      })
    );
  }

  clearAllCookies() {
    let cookies = document.cookie.split('; ');
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split('.');
      while (d.length > 0) {
        let cookieBase =
          encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
          '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
          d.join('.') +
          ' ;path=';
        let p = location.pathname.split('/');
        document.cookie = cookieBase + '/';
        while (p.length > 0) {
          document.cookie = cookieBase + p.join('/');
          p.pop();
        }
        d.shift();
      }
    }
  }
}
