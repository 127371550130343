import { Injectable, OnDestroy, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService implements OnDestroy {
  userPermissions: any;
  destroy$: any;
  constructor(private router: Router, private store: Store<reducers.State>) {}

  initializePermissions(): Promise<boolean> {
    const getLoggedInUserInfo$ = this.store.select(
      reducers.getLoggedInUserInfo
    );

    return new Promise((resolve, reject) => {
      if (this.userPermissions) {
        return resolve(true);
      }

      this.destroy$ = getLoggedInUserInfo$.subscribe((user) => {
        if (user == null) {
          return;
        }
        if (user?.fid) {
          this.userPermissions = user;
          resolve(false);
        }
      });
    });
  }

  hasPermission(path: string): boolean {
    if (!this.userPermissions) {
      this.__reRouteAccordingToUsersRole();
      return false;
    }

    switch (path) {
      // History
      case 'history': {
        return this.userPermissions.isAdmin;
      }

      // Policy editor
      case 'policyeditor': {
        return this.userPermissions.isAdmin;
      }

      // App selector
      case 'app-selector': {
        return this.userPermissions.isAdmin;
      }

      case 'user-guide':
        return this.userPermissions.isAdmin;

      case 'feedback':
        if (this.userPermissions.email.includes('@securly.com')) {
          return true;
        }
        return false
        
      case 'system-messages':
        return this.userPermissions.isAdmin;

      default:
        return this.__reRouteAccordingToUsersRole();
    }
  }

  private __reRouteAccordingToUsersRole(): boolean {
    this.router.navigate(['/chats']);
    return true;
  }

  ngOnDestroy() {
    this.destroy$.unsubscribe();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const path = route.routeConfig.path;

    const promise = new Promise<boolean>((resolve) => {
      if (this.userPermissions) {
        resolve(this.hasPermission(path));
      } else {
        // Fetch user permissions if not already fetched
        this.initializePermissions()
          .then(() => {
            let hasAccess = this.hasPermission(path);
            if (path) {
              resolve(hasAccess);
            } else {
              resolve(this.hasPermission(null));
            }
          })
          .catch(() => {
            resolve(false);
          });
      }
    });

    return promise;
  }
}
