/**
 * This is a snackbar component to be used throughout the APP
 * This works as a two in one snackbar.
 * type = error shows an error snackbar (red)
 * type = success shows a success snackbar (green)
 *
 * Parameters:
 *      data {
 *         message: 'THE TEXT YOU WANT TO SHOW'
 *         type: 'error' OR 'success'
 *      }
 */

import { Component, Inject, ElementRef, HostListener } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

export interface SnackbarInputs {
  type: string;
  message: string;
}

@Component({
  selector: 'sc-snackbar-component',
  template: `
    <div class="d-flex justify-content-between snack-bar-container">
      <div class="snackbarCheck">
        <i class="icon icon-check-single"></i>
      </div>
      <div class="text-baseline d-flex align-items-center">
        {{ data.message }}
      </div>
      <a
        href="javascript:void(0)"
        class="cancelBtn-snackbar"
        mat-raised-button
        (click)="snackBarRef.dismiss()"
      >
        <i class="icon icon-e-remove"></i>
      </a>
    </div>
  `,
  styles: [``],
})
export class SuccessSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarInputs,
    public snackBarRef: MatSnackBarRef<SuccessSnackbarComponent>,
    private elRef: ElementRef
  ) {
    setTimeout(() => {
      snackBarRef.dismiss();
    }, 5000);
  }
  @HostListener('document:click', ['$event'])
  clickedOutSideSnackbar(event: any) {
    if (!this.elRef.nativeElement.contains(event.target)) {
      this.snackBarRef.dismiss();
    }
  }
}
