import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { LoadLoggedInUserInfo } from './user-entity.actions';
import { of } from 'rxjs';
import { CoreService } from '@app/core/core.service';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import { httpErrorResponseHandler } from '@app/shared/http-error-response-handler';

@Injectable()
export class UserEntityEffects {
  lookupUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadLoggedInUserInfo.init),
      map((action: any) => action),
      switchMap((payload) => {
        return this.coreService
          .createHttp('LOAD_LOGGED_IN_USER_INFO', {}, payload, {})
          .pipe(
            map((res: any) => {
              if (res) {
                return LoadLoggedInUserInfo.success(res.body);
              }
            }),
            catchError(() =>
              of(
                LoadLoggedInUserInfo.failure({
                  message: 'Failed to get Logged In User info.',
                })
              )
            )
          );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private coreService: CoreService,
    private store: Store<reducers.State>
  ) {}
}
