import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { loadAllFeedback} from './feedback.actions';;
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { CoreService } from '../core/core.service';

@Injectable()
export class FeedbackEffects {
  loadAllFeedback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAllFeedback.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('LOAD_ALL_FEEDBACK', payload.payload, {}, {})
          .pipe(
            map((res: any) => {
              return loadAllFeedback.success(res.body);
            }),
            catchError(() =>
              of(
                loadAllFeedback.failure({
                  message: 'Failed to load all feedback.',
                })
              )
            )
          );
      })
    );
  });
  constructor(private actions$: Actions, private mdmCoreService: CoreService) {}
}
