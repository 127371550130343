import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  createAccount,
  editAccount,
  getAccountId,
  loadAllAccounts,
  lookupAccounts,
} from './history.actions';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { CoreService } from '@app/core/core.service';

@Injectable()
export class HistoryEffects {
  loadAllAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAllAccounts.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('LOAD_ALL_ACCOUNTS', payload.payload, {}, {})
          .pipe(
            map((res: any) => {
              return loadAllAccounts.success(res.body);
            }),
            catchError(() =>
              of(
                loadAllAccounts.failure({
                  message: 'Failed to load all accounts.',
                })
              )
            )
          );
      })
    );
  });
  createAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createAccount.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        console.log('payload', payload);
        return this.mdmCoreService
          .createHttp('CREATE_ACCOUNT', payload.payload, payload.payload, {})
          .pipe(
            map((res: any) => {
              return createAccount.success(res.body);
            }),
            catchError(() =>
              of(
                createAccount.failure({
                  message: 'Failed to create Account.',
                })
              )
            )
          );
      })
    );
  });
  editAccount$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editAccount.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        console.log('payload', payload);
        return this.mdmCoreService
          .createHttp('EDIT_ACCOUNT', payload.payload, payload.payload, {})
          .pipe(
            map((res: any) => {
              return editAccount.success(res.body);
            }),
            catchError(() =>
              of(
                editAccount.failure({
                  message: 'Failed to create Account.',
                })
              )
            )
          );
      })
    );
  });
  getAccountId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAccountId.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('GET_ACCOUNT', payload, {}, {})
          .pipe(
            map((res: any) => {
              return getAccountId.success(res.body);
            }),
            catchError(() =>
              of(
                getAccountId.failure({
                  message: 'Failed to get Customer Account.',
                })
              )
            )
          );
      })
    );
  });
  lookupAccounts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(lookupAccounts.init),
      debounceTime(600),
      map((payload: any) => payload),
      switchMap((payload) => {
        return this.mdmCoreService
          .createHttp('LOOKUP_ACCOUNTS', payload.payload, {}, {})
          .pipe(
            map((res: any) => {
              return lookupAccounts.success(res.body);
            }),
            catchError(() =>
              of(
                lookupAccounts.failure({
                  message: 'Failed to lookup accounts.',
                })
              )
            )
          );
      })
    );
  });
  constructor(private actions$: Actions, private mdmCoreService: CoreService) {}
}
