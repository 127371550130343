<div class="navbar main-sidebar sticky" *ngIf="displaySidebar()">
  <button class="toggler" (click)="toggleMenu()">
    <span class="line"></span>
    <span class="line"></span>
    <span class="line"></span>
  </button>
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
      <div class="sidebaricon d-none d-sm-none d-md-none d-lg-block d-xl-block">
        <img
          src="../../../assets/securly-logo.svg"
          alt="Securly Logo"
          (click)="navToHome()"
        />
        <span class="product caption"></span>
        <!-- <span class="product caption">Platform</span> -->
      </div>

      <div class="sidebaricon d-md-block d-sm-block d-lg-none">
        <img
          src="../../../assets/securly-logo.svg"
          alt="Securly Logo"
          (click)="navToHome()"
        />
      </div>
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <li routerLinkActive="active">
            <a href="javascript:void(0)" routerLink="/chats">
              <mat-icon
                class="chat-icon"
                title="chat icon"
                svgIcon="chat-module-icon"
              >
              </mat-icon>
              <span translate>Chat</span>
            </a>
          </li>
          <li
            routerLinkActive="active"
            isAuthorizedToView
            [featureName]="'history'"
          >
            <a href="javascript:void(0)" routerLink="/history">
              <mat-icon
                class="history-icon"
                title="history icon"
                svgIcon="history-icon"
              >
              </mat-icon>
              <span translate>History</span>
            </a>
          </li>
          <li
            routerLinkActive="active"
            isAuthorizedToView
            [featureName]="'policyeditor'"
          >
            <a href="javascript:void(0)" routerLink="/policyeditor">
              <mat-icon
                class="policy-editor-icon"
                title="policy editor icon"
                svgIcon="policy-editor-icon"
              >
              </mat-icon>
              <span translate>Policy Editor</span>
            </a>
          </li>
          <!-- <li routerLinkActive="active">
            <a href="javascript:void(0)" routerLink="/users">
              <i class="icon icon-hierarchy-53"></i>
              <span translate>Policy Map</span>
            </a>
          </li> -->
          <!-- <li routerLinkActive="active">
            <a href="javascript:void(0)" routerLink="/users">
              <i class="icon icon-single-01-2"></i>
              <span translate>Staff</span>
            </a>
          </li> -->
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
  </aside>
</div>
