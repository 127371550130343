import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Shell } from '../shell/shell.service';
import { FeedbackComponent} from './feedback.component';
import { authGuard } from '../shared/guards/auth.guard';


const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/feedback', pathMatch: 'full' },
    {
      path: 'feedback',
      component: FeedbackComponent,
      canActivate: [authGuard],
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class FeedbackRoutingModule { }