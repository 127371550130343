import { PaginatedData } from '@app/shared/interfaces/paginated-data.interface';
import {
  loadConfigSettings,
  loadPolicies,
  loadPolicySettings,
  saveConfigSettings,
  savePolicySettings,
} from './policy-editor-settings.actions';
import { SnackbarNotification } from '@app/shared/interfaces/snackbar-notification.interface';

export interface State {
  configSettings: any[];
  policySettings: any[];
  policies: any[];
  notification: SnackbarNotification;
}

export const initialState: State = {
  configSettings: [],
  policySettings: [],
  policies: [],
  notification: {
    type: '',
    data: {
      message: '',
    },
  },
};

// tslint:disable-next-line: typedef
export function reducer(state = initialState, action: any): State {
  let stateToReturn = state; // default

  switch (action.type) {
    case loadConfigSettings.success.type:
      console.log('configSettings', action);
      return Object.assign({}, state, {
        configSettings: action.settings,
      });
    case loadPolicies.success.type:
      console.log('loadPolicies', action);
      return Object.assign({}, state, {
        policies: action.policies,
      });
    case loadPolicySettings.success.type:
      console.log('loadPolicySettings', action);
      return Object.assign({}, state, {
        policySettings: action.settings,
      });
    case saveConfigSettings.success.type:
      return Object.assign({}, state, {
        notification: {
          type: 'SUCCESS',
          data: {
            message: 'Settings saved successfully.',
          },
        },
      });

    case savePolicySettings.success.type:
      return Object.assign({}, state, {
        notification: {
          type: 'SUCCESS',
          data: {
            message: 'Settings saved successfully.',
          },
        },
      });
    case savePolicySettings.failure.type:
      return Object.assign({}, state, {
        notification: {
          type: 'FAILURE',
          data: {
            message: 'Failed to save policy settings.',
          },
        },
      });
    case saveConfigSettings.failure.type:
      return Object.assign({}, state, {
        notification: {
          type: 'FAILURE',
          data: {
            message: 'Failed to save settings.',
          },
        },
      });
    default:
      stateToReturn = state;
      break;
  }
  return stateToReturn;
}

export const getPolicyEditorNotifications = (state: State) =>
  state.notification;
export const getConfigSettings = (state: State) => state.configSettings;
export const getPolicies = (state: State) => state.policies;
export const getPolicySettings = (state: State) => state.policySettings;
