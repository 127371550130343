import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ElementRef,
  ChangeDetectionStrategy,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  moveItemInArray,
  CdkDragDrop,
  CdkDragEnd,
  CdkDrag,
} from '@angular/cdk/drag-drop';
import {
  ColumnSorterService,
  ColumnInfo,
} from '../shared/directives/column-sorter.service';
import { ResizeColumnDirective } from '@app/shared/directives/resize-column.directive';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
// import { resetTable } from '@app/users/users.actions';
@Component({
  selector: 'app-accounts-column-sorter, button[app-accounts-column-sorter]',
  templateUrl: './history-column-sorter.component.html',
  styleUrls: ['./history-column-sorter.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnSorterService],
})
export class AccountsColumnSorterComponent implements OnInit, AfterViewInit {
  @Output()
  columnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input()
  columns: string[];
  @Input()
  columnNames: string[];
  @Input()
  defaultColumns: any;
  @Input()
  saveName?: string;
  columnInfo: ColumnInfo[];

  constructor(
    private elementRef: ElementRef,
    private columnSorterService: ColumnSorterService,
    private store: Store<reducers.State>
  ) {}

  ngOnInit() {
    this.columnInfo = this.columns.map((currElement, index) => {
      return {
        id: currElement,
        name: this.columnNames[index],
        hidden: false,
        disable: this.isColumnDisable(currElement),
      };
    });

    this.columnInfo = this.columnSorterService.loadSavedColumnInfo(
      this.columnInfo,
      this.saveName
    );

    // reset if saved info (localstorage) is out of sync with the columns passed from parent component
    this.columnInfo.forEach((col) => {
      if (!this.columns.includes(col.id)) {
        this.onReset();
      }
    });

    this.emitColumns(false);
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList += 'va-mat-button-no-input';
  }
  isColumnDisable(id: string) {
    const col = this.defaultColumns?.find((col: any) => col.id == id);
    return col?.disable;
  }
  columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(
      this.columnInfo,
      event.item.data.columnIndex,
      event.currentIndex
    );
    this.emitColumns(true);
  }
  toggleSelectedColumn(columnId: string) {
    const colFound = this.columnInfo.find((col) => col.id === columnId);
    colFound.hidden = !colFound.hidden;
    this.emitColumns(true);
  }

  private emitColumns(saveColumns: boolean) {
    // Only emit the columns on the next animation frame available
    window.requestAnimationFrame(() => {
      this.columnsChange.emit(
        this.columnInfo
          .filter((colInfo) => !colInfo.hidden)
          .map((colInfo) => colInfo.id)
      );
      if (saveColumns) {
        this.columnSorterService.saveColumnInfo(this.columnInfo, this.saveName);
      }
    });
  }
  onReset() {
    this.columnInfo = [...this.defaultColumns];
    this.columnInfo.forEach((col) => {
      col.hidden = false;
    });
    // this.store.dispatch(resetTable({}));
    this.emitColumns(true);
  }
}
