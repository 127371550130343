import {
  Directive,
  ElementRef,
  HostListener,
  HostBinding,
} from '@angular/core';

@Directive({
  selector: '[appStickToTop]',
})
export class StickyDirective {
  @HostBinding('class.sticky') sticky = false;
  bannerHeight: any;
  domElement: any;
  constructor(private elementRef: ElementRef) {
    this.domElement = this.elementRef.nativeElement;
  }
  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    debugger;
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.bannerHeight) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
  @HostListener('window:resize', ['$event.target'])
  onResize() {
    if (document.querySelector('#top-ui-banner')) {
      this.bannerHeight = document.querySelector('#top-ui-banner').clientHeight;
      this.handleScroll();
      this.domElement.style.marginBottom = -this.domElement.offsetHeight + 'px';
    }
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewChecked() {
    if (document.querySelector('#top-ui-banner')) {
      this.bannerHeight = document.querySelector('#top-ui-banner').clientHeight;
    } else {
      this.bannerHeight = 0;
    }
  }
}
