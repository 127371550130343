import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';

@Directive({
  selector: 'img[appDefaultFavicon]',
})
export class DefaultFaviconDirective implements OnChanges {
  public defaultImg = '/app/ang-7/dist/assets/images/defaultFavicon.png';
  @HostBinding('attr.src') @Input() src: any;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.src) {
      this.src = this.defaultImg;
    }
  }

  @HostListener('error', ['$event'])
  public onError() {
    this.src = this.defaultImg;
  }
}
