import { Component, OnInit } from '@angular/core';
import { finalize, skip, takeUntil } from 'rxjs/operators';
import {
  getAccountId,
  loadAllAccounts,
  lookupAccounts,
} from './history.actions';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Go } from '@app/router/router.actions';
import { SuccessSnackbarComponent } from '@app/shared/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { individualChathistory } from './individual-chat-history.mock';
import { ActivatedRoute } from '@angular/router';
import {
  getChatSessionById,
  loadAllChats,
  lookupChats,
} from '@app/chats/chats.actions';
import { Transcript } from '@app/shared/interfaces/transcript.interface';
import { PaginatedListPayload } from '@app/shared/interfaces/paginated-list-payload.interface';
import { OnDestroy } from '@angular/core';

@Component({
  selector: 'app-history-details',
  templateUrl: './history-details.component.html',
  styleUrls: ['./history-details.component.scss'],
})
export class HistoryDetailsComponent implements OnInit, OnDestroy {
  limit = 50;
  displayedColumns: string[] = ['title', 'date'];
  defaultColumns = [
    { id: 'title', name: 'Chats', hidden: false },
    { id: 'date', name: 'Date & time', hidden: false },
  ];
  historyFilterForm: FormGroup;
  dataObject: PaginatedListPayload = {
    offset: 0,
    limit: this.limit,
    sortAscending: false,
    sortColumns: ['date'],
  };
  columnNames: string[] = ['Chats', 'Date & Time'];
  dataSource: any = [];
  uuid: string = '';
  chatSummary: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  chatTranscript: Transcript[] = [];
  chats: Transcript[] = [];

  constructor(
    private store: Store<reducers.State>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {
    this.dataSource = [];
    this.historyFilterForm = this.formBuilder.group({
      searchfilter: [],
      filterBy: [],
    });
    this.dataSource = new MatTableDataSource(individualChathistory);
  }

  ngOnInit() {
    this.store.dispatch(loadAllChats.init({ payload: this.dataObject }));
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.uuid = params.id;
      if (this.uuid) {
        this.store.dispatch(getChatSessionById.init({ chatId: this.uuid }));
      }
    });
    this.store
      .select(reducers.getChatTranscript)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chatTranscript: Transcript[]) => {
        this.chatTranscript = chatTranscript;
      });
    this.store
      .select(reducers.getChatSummary)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chatSummary: string) => {
        console.log('summary', chatSummary);
        this.chatSummary = chatSummary;
      });
    this.store
      .select(reducers.getChats)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chats: any) => {
        this.chats = chats;
        this.dataSource = new MatTableDataSource(this.chats);
      });
  }

  getChatHistory(row: any) {
    this.store.dispatch(getChatSessionById.init({ chatId: row.uuid }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onSearch(event: any) {
    this.dataObject = Object.assign({}, this.dataObject, {
      searchText: event?.target?.value || null,
    });
    this.store.dispatch(lookupChats.init({ payload: this.dataObject }));
  }
}
