import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HistoryComponent } from './history.component';
import { Shell } from '@app/shell/shell.service';
import { HistoryDetailsComponent } from './history-details.component';
import { HistoryMainComponent } from './history-main.component';
import { authGuard } from '@app/shared/guards/auth.guard';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/history', pathMatch: 'full' },
    {
      path: 'history',
      component: HistoryMainComponent,
      canActivate: [authGuard],
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'list/1',
        },
        {
          path: 'list/:page',
          component: HistoryComponent,
        },
        {
          path: 'details/:id',
          component: HistoryDetailsComponent,
        },
      ],
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HistoryRoutingModule {}
