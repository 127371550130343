<div class="modal-container">
  <h2 *ngIf="headerText" class="dark-title py-2 mb-2">
    {{ headerText }}
  </h2>
  <p class="lighter-l-text pb-3">
    {{ modalDescription }}
  </p>

  <div class="btn-group" [ngClass]="{ 'flex-row-reverse': btnReverse }">
    <button class="button -secondary" (click)="cancel()">
      {{ cancelText || 'No' }}
    </button>
    <button class="button -primary" (click)="confirm()">
      {{ confirmText || 'Yes' }}
    </button>
  </div>
</div>
