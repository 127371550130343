import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication.service';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  constructor(private authService: AuthenticationService) {}

  logout(e: any) {
    this.authService.logout();
  }
}
