import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  loadConfigSettings,
  saveConfigSettings,
  savePolicySettings,
} from '../policy-editor-settings.actions';
import { Store } from '@ngrx/store';
import * as reducers from '../../reducers';
import { Subject, skip, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-custom-rules',
  templateUrl: './custom-rules.component.html',
  styleUrls: ['./custom-rules.component.scss'],
})
export class CustomRulesComponent implements OnInit, OnDestroy {
  @Input() poilicyName = 'Global Settings';
  @Input() settings: any = [];
  menuPosition: number;
  policyForm: FormGroup;
  policyIcon = {
    borderRadius: '50%',
    fontSize: '18px',
    fontFamily: '"metropolis-regular", sans-serif',
  };
  radioGroup: string = '';
  allPermissions: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentPolicyName = '';

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<reducers.State>,
    private route: ActivatedRoute
  ) {}
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.policyForm = this.formBuilder.group({});
      //get custom policy name or custom rules
      this.currentPolicyName =
        params['customPolicy'] || this.route.snapshot.routeConfig.path;

      if (this.currentPolicyName === 'customrules') {
        this.store.dispatch(loadConfigSettings.init({}));
      }
    });

    this.store
      .select(reducers.getConfigSettings)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((settings: any) => {
        if (settings) {
          this.settings = settings;
          this.populateForm();
        }
      });
    this.store
      .select(reducers.getPolicySettings)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((settings: any) => {
        if (settings) {
          this.settings = settings;
          this.populateForm();
        }
      });
  }
  onSubmit() {
    if (this.currentPolicyName === 'customrules') {
      this.store.dispatch(saveConfigSettings.init(this.policyForm.value));
    } else {
      this.store.dispatch(
        savePolicySettings.init({
          policy: this.currentPolicyName,
          data: this.policyForm.value,
        })
      );
    }
  }

  populateForm() {
    if (!this.settings) return;
    this.settings.map((policy: any, i: number) => {
      if (policy.type === 'checkbox') {
        this.policyForm.addControl(
          policy.controlName,
          this.formBuilder.control({
            value: policy.value,
            disabled: policy.disabled,
          })
        );
      } else if (policy.type === 'radio') {
        this.policyForm.addControl(
          policy.controlName,
          this.formBuilder.control({
            value: policy.value,
            disabled: policy.disabled,
          })
        );
      } else if (policy.type === 'textarea') {
        this.policyForm.addControl(
          policy.controlName,
          this.formBuilder.control({
            value: policy.value,
            disabled: policy.disabled,
          })
        );
      }
    });
  }

  get getControls() {
    const controls = (this.policyForm.get('policiesArray') as FormArray)
      .controls;
    return controls;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
