import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@core';
import { SharedModule } from '../shared';
import { ChatssRoutingModule } from './chats-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MarkdownModule } from 'ngx-markdown';

import { MatListModule } from '@angular/material/list';
import { ChatsComponent } from './chats.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatComponent } from './chat.component';
import { WebsocketService } from './websocket.service';
import { ReportChatModalComponent } from './report-chat-modal.component';
import { MARKDOWN_PROVIDER } from '@app/markdown';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule,
    MarkdownModule.forRoot({
      markedOptions: MARKDOWN_PROVIDER,
    }),
    ChatssRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
  ],
  declarations: [ChatsComponent, ChatComponent, ReportChatModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [MatSidenavModule, ChatComponent],
  providers: [WebsocketService],
})
export class ChatsModule {}
