export interface State {
  notification: any;
}

const initialState: State = {
  notification: {},
};

// tslint:disable-next-line: typedef
export function reducer(state = initialState, action: any): State {
  let stateToReturn = state; // default

  switch (action.type) {
  }
  return stateToReturn;
}
