import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggedInUserInfo } from '@app/user-entity/logged-in-user-info.interface';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as reducers from '../../../reducers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-password-change-modal',
  templateUrl: './password-change-modal.component.html',
  styleUrls: ['./password-change-modal.component.scss'],
})
export class PasswordChangeModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  passwordChangeForm: FormGroup;
  fieldTextType = false;
  userInfo: LoggedInUserInfo;
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<reducers.State>,
    private dialogRef: MatDialogRef<PasswordChangeModalComponent>
  ) {
    this.passwordChangeForm = this.formBuilder.group({
      password: [''],
      confirmPassword: [''],
    });
  }

  ngOnInit(): void {}

  /**
   * Checks that the passwords match, and if not assigns and error to them
   */
  checkPasswordsMatch() {
    if (
      this.passwordChangeForm.controls?.password?.touched &&
      this.passwordChangeForm.controls?.confirmPassword?.touched
    ) {
      if (
        this.passwordChangeForm.value?.password !==
        this.passwordChangeForm.value?.confirmPassword
      ) {
        this.passwordChangeForm.controls?.password?.setErrors({
          passwordsDoNotMatch: true,
        });
        this.passwordChangeForm.controls?.confirmPassword?.setErrors({
          passwordsDoNotMatch: true,
        });
      } else {
        if (this.passwordChangeForm.value?.password) {
          this.passwordChangeForm.controls?.password?.setErrors(null);
        }
        if (this.passwordChangeForm.value?.confirmPassword) {
          this.passwordChangeForm.controls?.confirmPassword?.setErrors(null);
        }
        if (this.passwordChangeForm.value?.password.length < 8) {
          this.passwordChangeForm.controls?.password?.setErrors({
            minLength: 8,
          });
        }
        if (this.passwordChangeForm.value?.confirmPassword.length < 8) {
          this.passwordChangeForm.controls?.confirmPassword?.setErrors({
            minLength: 8,
          });
        }
      }
    }
  }

  checkPasswordMatchError(controlName: string) {
    return (
      this.passwordChangeForm.controls[controlName]?.errors
        ?.passwordsDoNotMatch &&
      this.passwordChangeForm.controls[controlName]?.touched &&
      this.passwordChangeForm.controls[controlName]?.touched
    );
  }
  checkMinLengthError(controlName: string) {
    return (
      this.passwordChangeForm.controls[controlName]?.errors?.minLength &&
      this.passwordChangeForm.controls[controlName]?.touched &&
      this.passwordChangeForm.controls[controlName]?.touched
    );
  }
  roleFieldInError() {
    return (
      this.passwordChangeForm.controls.userRole.touched &&
      this.passwordChangeForm.controls.userRole.errors
    );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  changePasswordSubmit() {
    this.passwordChangeForm.markAllAsTouched();
    this.checkPasswordsMatch();
  }

  isFormInvalidOrUntouched() {
    return (
      this.passwordChangeForm.invalid ||
      !this.passwordChangeForm.value?.password ||
      !this.passwordChangeForm.value?.confirmPassword
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
