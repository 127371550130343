<div class="history-details grid_container">
  <h2>History Details</h2>
  <ng-container action-bar-items>
    <div class="filter-section">
      <form class="flex-grow-1" [formGroup]="historyFilterForm">
        <div class="form-group flex-grow-1">
          <div class="srh-btn">
            <i class="icon icon-search"></i>
          </div>
          <input
            type="text"
            (keyup)="onSearch($event)"
            placeholder="Search"
            formControlName="searchfilter"
            class="form-control filterControl reportSearch"
          />
        </div>
      </form>
      <!-- <app-sortby-selector></app-sortby-selector> -->
    </div>
  </ng-container>
  <div class="detail-section">
    <div class="side-table">
      <table mat-table [dataSource]="dataSource">
        <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
        <!-- name Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef disableClear scope="col">
            Chat Titles
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.title }}
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef disableClear scope="col">
            Date & Time
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.date | date : 'short' }}
          </td>
        </ng-container>
        <ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">No records found.</td>
          </tr>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="studentName">
          <th
            mat-header-cell
            *matHeaderCellDef
            [resizeColumn]="true"
            [index]="1"
            disableClear
            scope="col"
            mat-sort-header="studentName"
          >
            Student Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.studentName }}
          </td>
        </ng-container>
        <ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">No records found.</td>
          </tr>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="getChatHistory(row)"
        ></tr>
      </table>
    </div>
    <div class="summary-chat">
      <div class="summary-section">
        <h4 class="h6">Summary</h4>
        <div class="summary" *ngIf="chatSummary">
          {{ chatSummary }}
        </div>
      </div>
      <div class="chat-section">
        <app-chat [selectedChat]="true"></app-chat>
      </div>
    </div>
  </div>
</div>
