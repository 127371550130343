import { Component, ElementRef, OnInit, Type, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { deleteChat, loadAllChats, resetMetadata } from './chats.actions';
import { skip, Subject, takeUntil } from 'rxjs';
import { Transcript } from '@app/shared/interfaces/transcript.interface';
import { Go } from '@app/router/router.actions';
import { MatDialog } from '@angular/material/dialog';
import { YesNoGeneralModalComponent } from '@app/shared/components/yes-no-general-modal/yes-no-general-modal.component';
import { SuccessSnackbarComponent } from '@app/shared/success-snackbar.component';
import { FailureSnackbarComponent } from '@app/shared/failure-snackbar.component';
import { SnackbarNotification } from '@app/shared/interfaces/snackbar-notification.interface';
import { ActivatedRoute } from '@angular/router';
import { ChatSession } from '@app/shared/interfaces/chat-session.interface';

type ComponentMap = {
  [key: string]: [
    Type<SuccessSnackbarComponent | FailureSnackbarComponent>,
    string
  ];
};
@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss'],
})
export class ChatsComponent implements OnInit {
  chats: Transcript[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('drawer') public drawer: MatSidenav;
  loadingChats = false;
  componentMap: ComponentMap = {
    SUCCESS: [SuccessSnackbarComponent, 'success-snackbar'],
    FAILURE: [FailureSnackbarComponent, 'failure-snackbar'],
  };
  chatMetadata: any = {};
  selectedChat: string;
  constructor(
    private store: Store<reducers.State>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.firstChild.params.subscribe((params) => {
      this.selectedChat = params.chatid;
    });
    this.store
      .select(reducers.getChatsNotification)
      .pipe(skip(1))
      .subscribe((notification: SnackbarNotification) => {
        const [component, className] = this.componentMap[notification.type];
        this.snackBar.openFromComponent(component, {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: notification.data,
          panelClass: [className],
        });
      });
    this.store
      .select(reducers.getMetadata)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chatMetadata: Transcript[]) => {
        this.chatMetadata = chatMetadata;
      });
    this.store
      .select(reducers.getUpdateChatsStatus)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((status: boolean) => {
        if (status) {
          this.store.dispatch(resetMetadata());
          this.refreshChatHistoryList();
        }
      });
    this.store.dispatch(
      loadAllChats.init({
        payload: {
          offset: 0,
          sortColumns: ['date'],
          sortAscending: false,
          limit: 50,
        },
      })
    );

    this.store
      .select(reducers.getChats)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((chats: any) => {
        this.loadingChats = false;
        this.chats = chats;
      });
  }

  onChangeToggle() {
    this.drawer.toggle();
  }

  startNewChat() {
    this.store.dispatch(resetMetadata());
    this.refreshChatHistoryList();
    this.store.dispatch(
      new Go({
        path: [`/chats/1`],
      })
    );
  }

  refreshChatHistoryList() {
    this.loadingChats = true;
    this.store.dispatch(
      loadAllChats.init({
        payload: {
          offset: 0,
          sortColumns: ['date'],
          sortAscending: false,
          limit: 50,
        },
      })
    );
  }

  deleteChat(event: Event, chat: ChatSession) {
    event.stopPropagation();

    this.dialog
      .open(YesNoGeneralModalComponent, {
        width: '480px',
        data: {
          headerText: 'Delete chat?',
          modalDescription: `This will delete ${chat.title}.`,
          confirmText: 'Delete',
          cancelText: 'Cancel',
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // if selected chat is deleted, jump to new chat
          if (this.selectedChat === chat.uuid) {
            this.startNewChat();
          }
          this.store.dispatch(deleteChat.init({ chatId: chat.uuid }));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
