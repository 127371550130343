import { Injectable } from '@angular/core';
import { WindowRef } from './window.interface';
declare var aptrinsic: any;

@Injectable({
  providedIn: 'root',
})
export class GainsightService {
  constructor(private winRef: WindowRef) {}

  injectGainsightTag(
    tagID: string,
    scriptSrc = 'https://web-sdk.aptrinsic.com/api/aptrinsic.js'
  ) {
    const window = this.winRef.nativeWindow;
    const scriptName: any = 'aptrinsic';
    window[scriptName] =
      window[scriptName] ||
      ((...args: any[]) => {
        (window[scriptName]['q'] = window[scriptName]['q'] || []).push(...args);
      });
    window[scriptName]['p'] = tagID;

    const newScript = document.createElement('script');
    (newScript.async = !0), (newScript.src = scriptSrc + '?a=' + tagID);
    const scriptTags = document.getElementsByTagName('script')[0];
    if (scriptTags) {
      scriptTags?.parentNode?.insertBefore(newScript, scriptTags);
    } else {
      throw 'No script found';
    }
  }

  init(config: any) {
    aptrinsic('identify', config.user, config.account);
  }
}
