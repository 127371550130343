import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'pagination-component',
  template: `
    <div class="custom-pagination">
      <div class="d-flex justify-content-end">
        <div class="p-2 bd-highlight align-self-start">
          <div class="items-perpage-wrapper" *ngIf="currentPageLimit > 0">
            <div class="items-link">{{ 'Items per Page' }}</div>
            <select
              class="form-control form-control-sm items-perpage"
              (change)="setItemsPerPage($event.target.value)"
            >
              <!--<option *ngFor="let page of paginationControl.pages" [value]="page.label">{{ page.label }}</option>-->
              <option [value]="">{{ currentPageLimit }}</option>
              <option [value]="5">5</option>
              <option [value]="15">15</option>
              <option [value]="50">50</option>
              <option [value]="100">100</option>
            </select>
          </div>
          <nav
            class="bd-highlight paginationtab"
            aria-label="Page navigation example"
          >
            <ul class="pagination">
              <li
                class="page-item previous-page"
                [class.disabled]="paginationControl.isFirstPage()"
              >
                <a
                  href="javascript:void(0)"
                  class="page-link link-page-right"
                  *ngIf="!paginationControl.isFirstPage()"
                  (click)="paginationControl.previous()"
                >
                  <i class="icon icon-small-left-2"></i>
                </a>
              </li>
              <li
                *ngFor="let page of paginationControl.pages"
                [class.current]="paginationControl.getCurrent() === page.value"
                class="page-item previous-page"
              >
                <a
                  href="javascript:void(0)"
                  class="page-link link-page-right"
                  (click)="paginationControl.setCurrent(page.value)"
                  *ngIf="paginationControl.getCurrent() !== page.value"
                >
                  <span>{{ page.label }}</span>
                </a>
                <a
                  href="javascript:void(0)"
                  *ngIf="paginationControl.getCurrent() === page.value"
                  class="page-link link-page-right"
                >
                  <span>{{ page.label }}</span>
                </a>
              </li>
              <!-- <li class="page-item">
          <a href="javascript:void(0)" class="page-link">{{paginationControl.getCurrent()}}</a>
        </li>
        <li class="page-item">
          <span class="page-notlink">of</span>
        </li>
        <li class="page-item">
          <a href="javascript:void(0)" class="page-link" (click)="paginationControl.setCurrent(paginationControl.getLastPage())">
           {{paginationControl.getLastPage()}}</a>
        </li>-->
              <li
                class="page-item"
                [class.disabled]="paginationControl.isLastPage()"
              >
                <a
                  href="javascript:void(0)"
                  class="page-link last-page link-page-left"
                  *ngIf="!paginationControl.isLastPage()"
                  (click)="paginationControl.next()"
                >
                  <i _ngcontent-lcb-c1="" class="icon icon-small-right-3"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .bd-highlight {
        display: flex;
      }
      .page-link {
        cursor: pointer;
      }
      .current a {
        background: #0073e6;
      }
      .current a span {
        color: #fff;
      }
    `,
  ],
})
export class PaginationComponent implements OnInit {
  @Input() config: any;
  @Input() currentPageLimit: number;
  @Input() paginationControl: any;
  @Output() itemsPerPage = new EventEmitter();
  constructor() {}
  ngOnInit() {}
  setItemsPerPage(event: any) {
    this.itemsPerPage.emit(event);
  }
}
