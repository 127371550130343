import { PaginatedListPayload } from '@app/shared/interfaces/paginated-list-payload.interface';
import { createAsyncAction } from '../util';
import { createAction, props } from '@ngrx/store';
import { PaginatedData } from '@app/shared/interfaces/paginated-data.interface';

export const loadAllChats = createAsyncAction(
  '[Chats] Load all Chats',
  props<{ payload: PaginatedListPayload }>(),
  props<{ chats: PaginatedData }>(),
  props<{ message: string }>()
);

export const lookupChats = createAsyncAction(
  '[Chats] Lookup  Chats',
  props<{ payload: any }>(),
  props<{ Chats: any }>(),
  props<{ message: string }>()
);

export const deleteChat = createAsyncAction(
  '[Chats] Delete  Chat',
  props<{ chatId: string }>(),
  props<any>(),
  props<{ message: string }>()
);

export const getChatSessionById = createAsyncAction(
  '[Chats] Get Chat Session by ID',
  props<{ chatId: any }>(),
  props<{ chats: any }>(),
  props<{ message: string }>()
);

export const createAccount = createAsyncAction(
  '[Chats] Create  account',
  props<any>(),
  props<any>(),
  props<{ message: string }>()
);

export const reportChat = createAsyncAction(
  '[Chats] Report Chat',
  props<{
    report: { chat_uuid: string; positive: boolean; narrative: string };
  }>(),
  props<any>(),
  props<{ message: string }>()
);
export const showMetadataTitle = createAction(
  '[Chats] Show metadata title',
  props<any>()
);
export const resetMetadata = createAction('[Chats] Reset metadata title');
