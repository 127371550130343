import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import {
  loadConfigSettings,
  saveConfigSettings,
  loadPolicySettings,
  savePolicySettings,
  loadPolicies,
} from './policy-editor-settings.actions';
import { switchMap, map, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import { CoreService } from '@app/core/core.service';

@Injectable()
export class PolicyEditorEffects {
  loadConfigSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadConfigSettings.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('LOAD_CONFIG_SETTINGS', {}, {}, {})
          .pipe(
            map((res: any) => {
              return loadConfigSettings.success(res.body);
            }),
            catchError(() =>
              of(
                loadConfigSettings.failure({
                  message: 'Failed to load all accounts.',
                })
              )
            )
          );
      })
    );
  });

  loadPolicies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadPolicies.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('LOAD_POLICIES', payload, {}, {})
          .pipe(
            map((res: any) => {
              return loadPolicies.success(res.body);
            }),
            catchError(() =>
              of(
                loadPolicies.failure({
                  message: 'Failed to load policies.',
                })
              )
            )
          );
      })
    );
  });

  loadPolicySettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadPolicySettings.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('LOAD_POLICY_SETTINGS', payload, {}, {})
          .pipe(
            map((res: any) => {
              return loadPolicySettings.success(res.body);
            }),
            catchError(() =>
              of(
                loadPolicySettings.failure({
                  message: 'Failed to load policy settings.',
                })
              )
            )
          );
      })
    );
  });

  saveConfigSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveConfigSettings.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('SAVE_CONFIG_SETTINGS', payload, {}, {})
          .pipe(
            map((res: any) => {
              return saveConfigSettings.success(res.body);
            }),
            catchError(() =>
              of(
                saveConfigSettings.failure({
                  message: 'Failed to load all accounts.',
                })
              )
            )
          );
      })
    );
  });

  savePolicySettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(savePolicySettings.init),
      map((payload: any) => payload),
      switchMap((payload: any) => {
        return this.mdmCoreService
          .createHttp('SAVE_POLICY_SETTINGS', payload, {}, {})
          .pipe(
            map((res: any) => {
              return savePolicySettings.success(res.body);
            }),
            catchError(() =>
              of(
                savePolicySettings.failure({
                  message: 'Failed to update.',
                })
              )
            )
          );
      })
    );
  });
  constructor(private actions$: Actions, private mdmCoreService: CoreService) {}
}
