import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sub-nav',
  template: `
    <div class="sub-nav action-bar sub-nav-tab">
      <nav mat-tab-nav-bar [backgroundColor]="background">
        <a
          mat-tab-link
          class="smallBody"
          *ngFor="let link of links"
          [disabled]="!!link?.disabled"
          (click)="navToLink(link.link)"
          routerLinkActive
          #rla="routerLinkActive"
          [active]="isActive(link.link)"
          [ngClass]="{ 'reduced-font-size': reduceFontSize }"
        >
          {{ link.caption }}
        </a>
      </nav>
    </div>
  `,
  styles: [
    `
      .reduced-font-size {
        font-size: 0.8rem;
      }
    `,
  ],
})
export class SubNavComponent implements OnInit {
  @Input() links: any = [];
  @Input() background: any;
  @Input() reduceFontSize = false;
  activeLink = this.links[0];

  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit() {}

  navToLink(link: string[]) {
    this.router.navigate([link[0]]);
  }

  isActive(link: string[]) {
    let isActive = false;
    for (const segment of link) {
      if (this.router.url.indexOf(segment) > -1) {
        isActive = true;
      }
    }
    return isActive;
  }
}
