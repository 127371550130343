export function getCsrfToken() {
  if (!document.cookie) {
    return null;
  }
  const xsrfCookies = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith('XSRF-TOKEN' + '='));
  if (xsrfCookies.length === 0) {
    const isXsrfCookieExist = document.cookie.trim().startsWith('XSRF-TOKEN=');
    if (isXsrfCookieExist) {
      const xsrfCookie = document.cookie;
      return decodeURIComponent(xsrfCookie.split('=')[1]);
    }
  } else {
    return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }
}
