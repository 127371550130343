<section class="feedback-container"
    isAuthorizedToView
    [featureName]="'feedback'"
>
  <div class="header">
	<h2>Feedback</h2>
  </div>
  <table mat-table [dataSource]="feedback" class="mat-elevation-z8">
	<!-- Date Column -->
	<ng-container matColumnDef="date">
	  <th mat-header-cell *matHeaderCellDef> Date </th>
	  <td mat-cell *matCellDef="let feedback"> {{ feedback.date | date: 'short' }} </td>
	</ng-container>

	<!-- FID Column -->
	<ng-container matColumnDef="fid">
	  <th mat-header-cell *matHeaderCellDef> FID </th>
	  <td mat-cell *matCellDef="let feedback"> {{ feedback.fid }} </td>
	</ng-container>

	<!-- Reporter Column -->
	<ng-container matColumnDef="reporter">
	  <th mat-header-cell *matHeaderCellDef> Reporter</th>
	  <td mat-cell *matCellDef="let feedback"> {{ feedback.reporter }} </td>
	</ng-container>

	<!-- Positive Column -->
	<ng-container matColumnDef="positive">
	  <th mat-header-cell *matHeaderCellDef> Positive</th>
	  <td mat-cell *matCellDef="let feedback"> {{ feedback.positive}} </td>
	</ng-container>

	<!-- Narrative Column -->
	<ng-container matColumnDef="description">
	  <th mat-header-cell *matHeaderCellDef> Narrative</th>
	  <td mat-cell *matCellDef="let feedback" class="narrative-cell"> 
		{{ feedback.narrative }} 
	  </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <div class="text-left material-pagination" *ngIf="feedback?.length">
	<mat-paginator
	  [hidePageSize]="totalCount <= pageSizeOptions[0]"
	  [pageSizeOptions]="pageSizeOptions"
	  [length]="totalCount"
	  [showFirstLastButtons]="true"
	  [pageSize]="limit"
	  (page)="onPageChange($event)"
	>
	</mat-paginator>
  </div>
</section>
