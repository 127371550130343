import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@core';
import { SharedModule } from '../shared';
import { HistoryRoutingModule } from './history-routing.module';
import { HistoryComponent } from './history.component';
import { AccountsColumnSorterComponent } from './history-column-sorter.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { HistoryDetailsComponent } from './history-details.component';
import { HistoryMainComponent } from './history-main.component';
import { ChatsModule } from '@app/chats/chats.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    CoreModule,
    HistoryRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ChatsModule,
  ],
  declarations: [
    HistoryMainComponent,
    HistoryComponent,
    AccountsColumnSorterComponent,
    HistoryDetailsComponent,
  ],
})
export class HistoryModule {}
