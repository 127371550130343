import { Injectable } from '@angular/core';
declare var SecurlyPlatform: any;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  // Call identify to know the user.   This will also set a cookie that can
  // be used and verified by the backend.  Never initates a top level redirect
  // but may do a fetch.  This also handles the redirect from login, so you should
  // always try identify before calling login in order to parse any Oauth information
  // that might be in the url.
  async identify() {
    try {
      var payload = await SecurlyPlatform.Identify();
      // Returns the contents of the JWT token if everything was a success
      console.log(payload.email);
      return payload;
    } catch (error) {
      console.log('Force Login');
      this.ssoLogin('https://' + location.host + '/login');
    }
  }

  async ssoInit() {
    // Set your product's oauth clientid here - "securly_example_app" will only be allowed for testing
    await SecurlyPlatform.Init(
      'securly_example_app',
      'https://accounts.securly.com'
    );
  }

  // Call Login to initiate a top level login flow.  This will present the customer with a GUI
  // if they're not logged into the the central system, but otherwise will return immediatly.
  ssoLogin(force_auth_mech: string) {
    /* This uses window.location for the callback url because we want the callback to come here
      for the demo, but in production we'll require callback urls to be registered in advance */
    SecurlyPlatform.Login(window.location, force_auth_mech);
  }

  // Call logout to initiate SLO
  logout() {
    SecurlyPlatform.Logout().then(() => {
      window.location.href = '/';
    });
  }
}
