<div class="header">
  <a href="https://www.securly.com" class="s-logo text-center" aria-label="Securly"><img src="/assets/securly.svg" alt="Securly logo" title="Securly" width="120" height="31"></a>
</div>
<div class="newGrid_container box-container">
  <div class="in-container">
    <h1>We couldn’t find an account with those credentials</h1>
    <h3>Please check your login details and try again</h3>
    <img
      src="assets/securly-error-page.png"
      alt="Account not found"
    />
    <a class="button -primary" href="javascript:void(0)" (click)="logout($event)">Back to login</a>
  </div>
</div>