import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store, State } from '@ngrx/store';
import * as reducers from '@app/reducers';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-report-chat-modal',
  templateUrl: 'report-chat-modal.component.html',
  styleUrls: ['./report-chat-modal.component.scss'],
})
export class ReportChatModalComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  reportChatForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private store: Store<reducers.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReportChatModalComponent>
  ) {
    this.reportChatForm = this.formBuilder.group({
      narrative: ['', Validators.required],
    });
  }

  ngOnInit() {}
  submitReport() {
    if (!this.reportChatForm.valid) return;
    this.dialogRef.close(this.reportChatForm.value);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
