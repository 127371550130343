<div class="modal-container">
  <div class="modalContent m-3 mt-5 text-center">
    <img src="assets/artboard.jpg" alt="Admin privilage" />
    <p>
      Your account was logged in from another browser. To use again from here,
      click the button below.
    </p>
    <button (click)="reloadApp()" class="btn btn-primary use-here-btn">
      Use Here
    </button>
  </div>
</div>
