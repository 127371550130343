import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  HostBinding,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject, skip, takeUntil } from 'rxjs';
import * as CONFIG_SETTINGS from '../../../../templates/config-settings.json';
import {
  loadConfigSettings,
  loadPolicies,
  saveConfigSettings,
} from './policy-editor-settings.actions';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import { SnackbarNotification } from '@app/shared/interfaces/snackbar-notification.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FailureSnackbarComponent } from '@app/shared/failure-snackbar.component';
import { SuccessSnackbarComponent } from '@app/shared/success-snackbar.component';

@Component({
  selector: 'policy-editor',
  templateUrl: './policy-editor-settings.component.html',
  styleUrls: ['policy-editor-settings.component.scss'],
})
export class PolicyEditorSettingsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  policyIcon = {
    borderRadius: '50%',
    fontSize: '18px',
    fontFamily: '"metropolis-regular", sans-serif',
  };

  policies: [];
  componentMap: any = {
    FAILURE: [FailureSnackbarComponent, 'mat-snack-bar-failure'],
    SUCCESS: [SuccessSnackbarComponent, 'mat-snack-bar-success'],
  };
  constructor(
    private snackBar: MatSnackBar,
    private store: Store<reducers.State>
  ) {
    this.store
      .select(reducers.getPolicies)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((policies: any) => {
        if (policies) {
          this.policies = policies;
        }
      });
    this.store
      .select(reducers.getPolicyEditorNotifications)
      .pipe(skip(1))
      .subscribe((notification: SnackbarNotification) => {
        const [component, className] = this.componentMap[notification.type];
        this.snackBar.openFromComponent(component, {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          data: notification.data,
          panelClass: [className],
        });
      });
  }

  ngOnInit() {
    this.store.dispatch(loadPolicies.init({}));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
