<div
  #appSelectorDropdown="ngbDropdown"
  ngbDropdown
  placement="bottom-right"
  [autoClose]="'outside'"
>
  <a
    href="javascript:void(0)"
    class="d-inline-block nav-link btn p-0"
    ngbDropdownToggle
    id="appSwitcherDropdown"
    role="button"
  >
    <i class="icon icon-apps m-0 mx-2"></i>
  </a>
  <div
    ngbDropdownMenu
    aria-labelledby="appSwitcherDropdown"
    class="appSwitcherDropdown dropdown-menu dropdown-menu-right"
  >
    <div>
      <div
        class="dropdown-item dropdown-value section-title d-flex justify-content-between"
        ngbDropdownItem
      >
        <p class="dropdown-section-title regularBody font medium m-0">
          Switch to...
        </p>
      </div>
      <ng-container>
        <a
          target="_blank"
          href="http://securly.com/app/login"
          class="dropdown-item dropdown-value"
          ngbDropdownItem
        >
          <div class="dropdown-text dark-text font medium">
            <div class="app-logo">
              <img src="assets/scss/logos/filter-logo.svg" alt="" />
            </div>
            Filter
          </div>
        </a>
        <a
          target="_blank"
          href="http://securly.com/app/login"
          class="dropdown-item dropdown-value"
          ngbDropdownItem
        >
          <div class="dropdown-text dark-text font medium">
            <div class="app-logo">
              <img src="assets/scss/logos/auditor.svg" alt="" />
            </div>
            Auditor
          </div>
        </a>
        <a
          target="_blank"
          href="https://vms.securly.com"
          class="dropdown-item dropdown-value"
          ngbDropdownItem
        >
          <div class="dropdown-text dark-text font medium">
            <div class="app-logo">
              <img src="assets/scss/logos/visitor-logo.svg" alt="" />
            </div>
            Visitor
          </div>
        </a>
      </ng-container>

      <ng-container>
        <div class="dropdown-item dropdown-value section-title" ngbDropdownItem>
          <p class="dropdown-section-title caption font medium m-0">
            {{ 'Discover' | uppercase }}
          </p>
        </div>
        <ng-container>
          <a
            href="https://www.securly.com/reveal"
            target="_blank"
            class="dropdown-item dropdown-value"
            ngbDropdownItem
          >
            <div class="dropdown-text dark-text font medium">
              <div class="app-logo">
                <img src="assets/scss/logos/reveal-logo.svg" alt="" />
              </div>
              Reveal
            </div>
          </a>
          <a
            href="https://rhithm.app"
            target="_blank"
            class="dropdown-item dropdown-value"
            ngbDropdownItem
          >
            <div class="dropdown-text dark-text font medium">
              <div class="app-logo">
                <img class="rhithm" src="assets/scss/logos/rhithm.svg" alt="" />
              </div>
              Rhithm
            </div>
          </a>
        </ng-container>
        <a
          href="https://www.securly.com/360-cloud"
          target="_blank"
          class="dropdown-item dropdown-value"
          ngbDropdownItem
        >
          <div class="dropdown-text dark-text font medium">
            <div class="app-logo"><i class="icon icon-e-add"></i></div>
            More Securly products...
          </div>
        </a>
      </ng-container>
    </div>
  </div>
</div>
