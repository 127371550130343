<section class="grid-container">
  <div class="policy-wrapper">
    <div class="side-nav-wrapper sticky">
      <div id="wrapper">
        <nav id="sidebar" class="sidebar">
          <ul class="navigation">
            <li
              tabindex="-1"
              routerLink="customrules"
              routerLinkActive="active"
            >
              <button>
                <ngx-avatar
                  [style]="policyIcon"
                  class="global-settings"
                  name="G"
                  [size]="32"
                >
                </ngx-avatar>
                <div class="policyText">
                  {{ 'Global settings' }}
                </div>
              </button>
            </li>
            <ng-container *ngFor="let policy of policies">
              <li
                tabindex="-1"
                [routerLink]="['policy', policy]"
                routerLinkActive="active"
              >
                <button>
                  <ngx-avatar
                    [style]="policyIcon"
                    class="custom-policy"
                    [name]="policy?.trim()?.charAt(0)"
                    [size]="32"
                  >
                  </ngx-avatar>
                  <div class="policyText">
                    {{ policy }}
                  </div>
                </button>
              </li>
            </ng-container>
          </ul>
        </nav>
      </div>
    </div>
    <div class="settings-container">
      <router-outlet class="tab-content"></router-outlet>
    </div>
  </div>
</section>
