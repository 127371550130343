import { Component, OnInit, OnDestroy } from '@angular/core';
import { loadAllFeedback} from './feedback.actions';
import { Store } from '@ngrx/store';
import * as reducers from '../reducers';
import { SelectionModel } from '@angular/cdk/collections';
import { PaginatedListPayload } from '../shared/interfaces/paginated-list-payload.interface';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  feedback: any[] = [];
  displayedColumns: string[] = ['date', 'fid', 'reporter', 'positive', 'description'];
  totalCount = 0;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  limit: number = 5;
  selection = new SelectionModel<any>(true, []);
  currentPage = 0;
  dataSource = new MatTableDataSource<any>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading = false;

  dataObject: PaginatedListPayload = {
    offset: 0,
    limit: this.limit,
    sortAscending: false,
    sortColumns: ['reporter'],
    allSessions: true,
  };

  constructor(
    private store: Store<reducers.State>,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(loadAllFeedback.init({ payload: this.dataObject }));
    this.store.select(reducers.getFeedback).pipe(takeUntil(this.destroy$)).subscribe((feedback: any) => {
      this.feedback = feedback;
      this.dataSource = new MatTableDataSource(this.feedback);
      this.isLoading = false;
    });

    this.store.select(reducers.getFeedbackTotalCount).pipe(takeUntil(this.destroy$)).subscribe((totalCount: number) => {
      this.totalCount = totalCount;
    });

    this.store.select(reducers.getFeedbackOffset).pipe(takeUntil(this.destroy$)).subscribe((offset: number) => {
      this.currentPage = offset / this.limit;
    });
  }
  sortData(sort: any) {
    this.dataObject = Object.assign({}, this.dataObject, {
      sort: [sort.active || 'primaryContactName'],
    });
    this.store.dispatch(loadAllFeedback.init({ payload: this.dataObject }));
  }

  onPageChange(event: any) {
    window.scrollTo(0, 0);
    this.currentPage = event.pageIndex;
    this.dataObject = {
      ...this.dataObject,
      limit: event.pageSize,
      offset: event.pageSize * event.pageIndex,
    };
    this.loadFeedback();
  }

  loadFeedback() {
    this.isLoading = true;
    this.store.dispatch(loadAllFeedback.init({ payload: this.dataObject }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}