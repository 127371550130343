export const icons = [
  { name: 'add-icon', url: '../../../assets/scss/icons/icon-add.svg' },
  { name: 'list-icon', url: '../../../assets/scss/icons/icon-listview.svg' },
  { name: 'grid-icon', url: '../../../assets/scss/icons/icon-gridview.svg' },
  {
    name: 'back-arrow-icon',
    url: '../../../assets/scss/icons/icon-go-back-arrow.svg',
  },
  { name: 'info-icon', url: '../../../assets/scss/icons/icon-info.svg' },
  { name: 'more-icon', url: '../../../assets/scss/icons/icon-more.svg' },
  {
    name: 'site-color',
    url: '../../../assets/scss/icons/icon-24-px-site-color.svg',
  },
  {
    name: 'students-color',
    url: '../../../assets/scss/icons/icon-24-px-students-color.svg',
  },
  {
    name: 'sound-color',
    url: '../../../assets/scss/icons/icon-24-px-sound-color.svg',
  },
  {
    name: 'chat-color',
    url: '../../../assets/scss/icons/icon-24-px-chat-color.svg',
  },
  {
    name: 'allow-icon',
    url: '../../../assets/scss/icons/icon-allowed-checkbox.svg',
  },
  { name: 'add-link', url: '../../../assets/scss/icons/icon-add-link.svg' },
  { name: 'icon-link', url: '../../../assets/scss/icons/icon-link.svg' },
  {
    name: 'icon-collection',
    url: '../../../assets/scss/icons/icon-collection.svg',
  },
  {
    name: 'add-collection',
    url: '../../../assets/scss/icons/add-collection.svg',
  },
  { name: 'print-icon', url: '../../../assets/scss/icons/icon-print.svg' },
  {
    name: 'save-screen-icon',
    url: '../../../assets/scss/icons/icon-save-screenshot.svg',
  },
  { name: 'expand-icon', url: '../../../assets/scss/icons/icon-expand.svg' },
  {
    name: 'waiting-img',
    url: '../../../assets/scss/icons/icon-browser-notopen.svg',
  },
  {
    name: 'student-not-online-icon',
    url: '../../../assets/scss/icons/icon-student-not-online.svg',
  },
  { name: 'zoomin-icon', url: '../../../assets/scss/icons/icon-zoom-in.svg' },
  {
    name: 'student-excluded-icon',
    url: '../../../assets/scss/icons/icon-student-excluded.svg',
  },
  {
    name: 'waiting-device-icon',
    url: '../../../assets/scss/icons/icon-waiting-on-device.svg',
  },
  {
    name: 'zoom-out-icon',
    url: '../../../assets/scss/icons/icon-zoom-out.svg',
  },
  {
    name: 'screen-lock-icon',
    url: '../../../assets/scss/images/class-icons/screen-lock.svg',
  },
  {
    name: 'site-lock-icon',
    url: '../../../assets/scss/images/class-icons/site-lock.svg',
  },
  {
    name: 'push-url-icon',
    url: '../../../assets/scss/images/class-icons/push-url.svg',
  },
  {
    name: 'screen-share-icon',
    url: '../../../assets/scss/images/class-icons/share-screen.svg',
  },
  {
    name: 'stop-screen-share-icon',
    url: '../../../assets/scss/images/class-icons/stop_screenshare.svg',
  },
  {
    name: 'sound-icon',
    url: '../../../assets/scss/images/class-icons/sound.svg',
  },
  {
    name: 'lockon-icon',
    url: '../../../assets/scss/images/class-icons/lockon.svg',
  },
  {
    name: 'active-message-icon',
    url: '../../../assets/scss/icons/icon-active-message.svg',
  },
  { name: 'message-icon', url: '../../../assets/scss/icons/icon-message.svg' },
  {
    name: 'screenlock-filled-icon',
    url: '../../../assets/scss/icons/icon-screenlock-filled.svg',
  },
  {
    name: 'raisehand-filled-icon',
    url: '../../../assets/scss/icons/icon-raisehand-filled.svg',
  },
  {
    name: 'announcement-filled-icon',
    url: '../../../assets/scss/icons/icon-announcement-filled.svg',
  },
  {
    name: 'sitelock-filled-icon',
    url: '../../../assets/scss/icons/icon-sitelock-filled.svg',
  },
  {
    name: 'chat-icon',
    url: '../../../assets/scss/images/class-icons/message.svg',
  },
  {
    name: 'settings-icon',
    url: '../../../assets/scss/icons/icon-menu-setup.svg',
  },
  {
    name: 'user-icon',
    url: '../assets/scss/icons/icon-menu-staff.svg',
  },
  {
    name: 'device-icon',
    url: '../../../assets/scss/icons/icon-menu-devices.svg',
  },
  {
    name: 'class-icon',
    url: '../../../assets/scss/icons/icon-menu-classes.svg',
  },
  {
    name: 'weblink-icon',
    url: '../../../assets/scss/icons/icon-menu-weblinks.svg',
  },
  {
    name: 'clone-icon',
    url: '../../../assets/scss/icons/icon-clone.svg',
  },
  {
    name: 'calendar-icon',
    url: '../../../../assets/scss/icons/icon-calendar.svg',
  },
  {
    name: 'warning-icon',
    url: '../../../assets/scss/images/warning-notice.svg',
  },
  {
    name: 'add-circle-icon',
    url: '../../../assets/scss/images/class-icons/icon-add-new.svg',
  },
  {
    name: 'stop-icon',
    url: '../../../assets/scss/images/class-icons/icon-stop.svg',
  },
  {
    name: 'online-icon',
    url: '../../../../assets/scss/icons/icon-8-px-dot.svg',
  },
  {
    name: 'offline-icon',
    url: '../../../../assets/scss/icons/icon-8-px-offline.svg',
  },
  {
    name: 'delete-icon',
    url: '../../../assets/scss/icons/icon-delete.svg',
  },
  {
    name: 'questions-icon',
    url: '../../../assets/scss/icons/icon-question.svg',
  },
  {
    name: 'sync-icon',
    url: '../../../assets/scss/icons/icon-sync.svg',
  },
  {
    name: 'guide-icon',
    url: '../../../assets/scss/icons/icon-guide.svg',
  },
  {
    name: 'icon-share',
    url: '../../../assets/scss/icons/icon-share.svg',
  },
  {
    name: 'info-icon',
    url: '../../../assets/scss/icons/icon-info.svg',
  },
  {
    name: 'smile-icon',
    url: '../../../assets/scss/icons/smile.svg',
  },
  {
    name: 'now-sharing-green-icon',
    url: '../../../assets/scss/icons/now-sharing-green.svg',
  },
  {
    name: 'student-screen-share-icon',
    url: '../../../assets/scss/icons/icon-student-screen-share.svg',
  },
  {
    name: 'notice-icon',
    url: '../../../assets/scss/icons/icon-notice.svg',
  },
  {
    name: 'chat-module-icon',
    url: '../../../assets/scss/icons/chat-module-icon.svg',
  },
  {
    name: 'history-icon',
    url: '../../../assets/scss/icons/activites_unselected.svg',
  },
  {
    name: 'policy-editor-icon',
    url: '../../../assets/scss/icons/policy-editor-icon.svg',
  },
  {
    name: 'thumps-up-icon',
    url: '../../../assets/scss/icons/icon-thumbs-up.svg',
  },
  {
    name: 'thumps-down-icon',
    url: '../../../assets/scss/icons/icon-thumbs-down.svg',
  },
  {
    name: 'report-icon',
    url: '../../../assets/scss/icons/icon-report-action.svg',
  },
];
