import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthorizationService } from '@app/core/authorization.service';
import { Observable } from 'rxjs';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const auth = inject(AuthorizationService);
  const router = inject(Router);

  return auth
    .canActivate(route, state)
    .then((hasAccess: boolean) => {
      //Redirect if user doesnt have access
      if (!hasAccess) {
        router.navigate(['/chats']);
      }
      return hasAccess;
    })
    .catch((error: any) => {
      console.error(error);
      return false;
    });
};
