import { createAsyncAction } from '../util';
import { props } from '@ngrx/store';

export const loadConfigSettings = createAsyncAction(
  '[Policy Editor] Load Config Settings',
  props<any>(),
  props<{ users: any }>(),
  props<{ message: string }>()
);

export const loadPolicies = createAsyncAction(
  '[Policy Editor] Load Policies',
  props<any>(),
  props<{ users: any }>(),
  props<{ message: string }>()
);

export const loadPolicySettings = createAsyncAction(
  '[Policy Editor] Load Policy Settings',
  props<{ policy: string }>(),
  props<{ users: any }>(),
  props<{ message: string }>()
);

export const saveConfigSettings = createAsyncAction(
  '[Policy Editor] Save Config Settings',
  props<any>(),
  props<{ users: any }>(),
  props<{ message: string }>()
);

export const savePolicySettings = createAsyncAction(
  '[Policy Editor] Save Policy Settings',
  props<{ policy: string; data: any }>(),
  props<{ users: any }>(),
  props<{ message: string }>()
);
