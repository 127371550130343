<section class="grid_container">
  <div class="devices-header top-section actions-container">
    <h2>History</h2>
  </div>

  <va-mat-table
    matSort
    matSortStart="desc"
    (matSortChange)="sortData($event)"
    [useActionBar]="!isPrivacyEnabled"
  >
    <ng-container action-bar-items>
      <div class="filter-section">
        <form class="flex-grow-1" [formGroup]="accountsFilterForm">
          <div class="form-group flex-grow-1">
            <div class="srh-btn">
              <i class="icon icon-search"></i>
            </div>
            <input
              type="text"
              (keyup)="onSearch($event)"
              placeholder="Search"
              formControlName="searchfilter"
              class="form-control filterControl search-input"
            />
          </div>
        </form>
        <div
          *ngIf="!selection?.selected?.length"
          class="filter-icon position-relative"
        >
          <!-- <button class="btn btn-light">
              <i class="icon icon-share"></i>
            </button> -->
          <button class="btn btn-light">
            <i
              class="icon icon-reload-2"
              matTooltip="Refresh"
              matTooltipPosition="above"
            ></i>
          </button>
          <button
            class="btn btn-light"
            app-accounts-column-sorter
            [(columns)]="displayedColumns"
            [columnNames]="columnNames"
            [defaultColumns]="defaultColumns"
            [saveName]="'accounts-blocklist-table'"
          >
            <i class="icon icon-settings-gear"></i>
          </button>
        </div>
      </div>
    </ng-container>

    <div class="loader">
      <loading-indicator class="v-h-center" [show]="isLoading" [size]="2">
      </loading-indicator>
    </div>
    <ng-container *ngIf="isPrivacyEnabled; else historyTable">
      <!-- PRIVACY ENABLED -->
      <div class="card text-center">
        <div
          class="card-body card-body-privacy mb-4 noDataImg col-md-6 offset-md-3"
        >
          <img
            class="privacy-card-image"
            src="assets/scss/images/privacy.svg"
            alt="An illustration of a blue shield with a checkmark"
          />
          <p class="lighter-text">
            History has been disabled to protect user privacy. To view history,
            please disable the Enhanced privacy mode under Global Settings.
          </p>
        </div>
      </div>
      <!-- PRIVACY ENABLED -->
    </ng-container>
    <ng-template #historyTable>
      <table
        mat-table
        [dataSource]="dataSource"
        [hidden]="isLoading || !dataSource"
      >
        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        <!-- <ng-container matColumnDef="checkbox">
          <th
            mat-header-cell
            *matHeaderCellDef
            [resizeColumn]="false"
            [index]="0"
            scope="col"
            width="50"
          >
          </mat-checkbox>
        </td>
      </ng-container> -->
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th
            mat-header-cell
            *matHeaderCellDef
            [resizeColumn]="true"
            [index]="3"
            disableClear
            scope="col"
            mat-sort-header="date"
            width="150"
          >
            Date
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element?.date | date : 'short' }}
          </td>
        </ng-container>
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th
            mat-header-cell
            *matHeaderCellDef
            [resizeColumn]="true"
            [index]="1"
            disableClear
            scope="col"
            mat-sort-header="email"
            width="180"
          >
            Student Email
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              style="width: 100%"
              matTooltip="{{ element?.email }}"
              matTooltipPosition="above"
              matTooltipClass="title-tooltip"
              class="ellipsis truncate-two-lines"
            >
              {{ element?.email }}
            </div>
          </td>
        </ng-container>

        <!-- title Column -->
        <ng-container matColumnDef="title">
          <th
            mat-header-cell
            *matHeaderCellDef
            [resizeColumn]="true"
            [index]="4"
            disableClear
            scope="col"
          >
            Chat Titles
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              style="width: 100%"
              matTooltip="{{ element?.title }}"
              matTooltipPosition="above"
              matTooltipClass="title-tooltip"
              class="ellipsis truncate-two-lines"
            >
              {{ element?.title }}
            </div>
          </td>
        </ng-container>

        <!-- summary name Column -->
        <ng-container matColumnDef="summary">
          <th
            mat-header-cell
            *matHeaderCellDef
            [resizeColumn]="true"
            [index]="4"
            disableClear
            scope="col"
          >
            Chat Summary
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              style="width: 100%"
              matTooltip="{{ element?.summary }}"
              matTooltipPosition="above"
              matTooltipClass="title-tooltip"
              class="ellipsis truncate-two-lines"
            >
              {{ element?.summary }}
            </div>
          </td>
        </ng-container>
        <!-- chatTitle Column -->
        <ng-container matColumnDef="chatTitle">
          <th
            mat-header-cell
            *matHeaderCellDef
            [resizeColumn]="true"
            [index]="4"
            disableClear
            scope="col"
          >
            Chat Title
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              style="width: 100%"
              matTooltip="{{ element?.chatTitle ? 'Public' : 'Private' }}"
              matTooltipPosition="above"
              matTooltipClass="title-tooltip"
              class="ellipsis truncate-two-lines"
            >
              {{ element?.chatTitle }}
            </div>
          </td>
        </ng-container>
        <ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="9999">No records found.</td>
          </tr>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="getChatHistory(row)"
        ></tr>
      </table>
      <div
        class="text-left material-pagination"
        *ngIf="dataSource?.data?.length"
      >
        <mat-paginator
          [hidePageSize]="totalCount <= pageSizeOptions[0]"
          [pageSizeOptions]="pageSizeOptions"
          [length]="totalCount"
          [showFirstLastButtons]="true"
          [pageSize]="limit"
          (page)="onPageChange($event)"
        >
        </mat-paginator>
      </div>
    </ng-template>
  </va-mat-table>
</section>
