<section class="grid_container">
  <mat-drawer-container class="drawer-container" autosize>
    <mat-drawer opened="true" #drawer class="drawer-sidenav" mode="side">
      <div class="actions">
        <div>
          <button mat-raised-button (click)="startNewChat()">
            Start new Chat
          </button>
        </div>
        <button
          class="button -nostyle-btn toggle-btn"
          *ngIf="drawer.opened"
          (click)="onChangeToggle()"
        >
          <i class="icon icon-left-arrow"></i>
        </button>
      </div>
      <mat-divider></mat-divider>
      <div class="list">
        <div class="loader">
          <loading-indicator
            class="v-h-center"
            [show]="loadingChats"
            [size]="2"
          >
          </loading-indicator>
        </div>
        <mat-nav-list>
          <mat-list-item
            *ngIf="chatMetadata.title"
            [routerLink]="chatMetadata.uuid"
            class="chat-title"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="chat-wrapper">
              <mat-icon class="chat-icon" title="chat icon" svgIcon="chat-icon">
              </mat-icon>
              <div
                style="width: 100%"
                matTooltip="{{ chatMetadata?.title }}"
                matTooltipPosition="above"
                matTooltipClass="title-tooltip"
                class="ellipsis link-caption ms-2"
              >
                {{ chatMetadata.title }}
              </div>
              <button
                class="delete-btn"
                (click)="deleteChat($event, chatMetadata)"
              >
                <i class="icon icon-bin-2"></i>
              </button>
            </div>
          </mat-list-item>
          <mat-list-item
            *ngFor="let chat of chats; let i = index"
            [routerLink]="chat.uuid"
            class="chat-title"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
            matTooltip="{{ chat?.title }}"
            matTooltipPosition="above"
          >
            <div class="chat-wrapper">
              <mat-icon class="chat-icon" title="chat icon" svgIcon="chat-icon">
              </mat-icon>
              <div style="width: 100%" class="ellipsis link-caption">
                {{ chat.title }}
              </div>
              <button class="delete-btn" (click)="deleteChat($event, chat)">
                <i class="icon icon-bin-2"></i>
              </button>
            </div>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-drawer>

    <div class="drawer-sidenav-content">
      <button
        class="toggle-btn"
        *ngIf="!drawer.opened"
        (click)="onChangeToggle()"
      >
        <i class="icon icon-right-arrow"></i>
      </button>
      <router-outlet class="tab-content"></router-outlet>
    </div>
  </mat-drawer-container>
</section>
