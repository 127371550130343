import { createAsyncAction } from '../util';
import { props } from '@ngrx/store';
import { LoggedInUserInfo } from './logged-in-user-info.interface';

export const LoadLoggedInUserInfo = createAsyncAction(
  '[User Entity] Get Logged In User Info',
  props<any>(),
  props<LoggedInUserInfo>(),
  props<{ message: string }>()
);
