import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'va-mat-table',
  templateUrl: './va-mat-table.component.html',
  styleUrls: ['./va-mat-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VaMatTableComponent implements OnInit {
  @Input() useActionBar = true;
  constructor() {}

  ngOnInit() {}
}
