import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Back } from '@app/router/router.actions';
import { Store } from '@ngrx/store';
import * as reducers from '../../../reducers';

@Component({
  selector: 'app-save-and-cancel-banner',
  templateUrl: './save-and-cancel-banner.component.html',
  styleUrls: ['./save-and-cancel-banner.component.scss'],
})
export class SaveAndCancelBannerComponent implements OnInit {
  @Input() saveButtonText = 'Save';
  @Input() bannerText = '';
  @Input() subText = '';
  @Input() isBackBtnActive = true;
  @Input() showCancelBtn = true;
  @Input() disableBtn = false;
  @Output() submitForm = new EventEmitter();

  constructor(private store: Store<reducers.State>) {}

  ngOnInit() {}
  onGoBack() {
    this.store.dispatch(new Back());
  }
  onSubmit() {
    this.submitForm.emit(true);
  }
  getBannerText(): string {
    return this.bannerText ? this.bannerText : '';
  }
  getBannerSubText(): string {
    return this.subText ? this.subText : '';
  }
  getSaveButtonText(): string {
    return this.saveButtonText ? this.saveButtonText : 'Save';
  }
}
