import { createReducer, on } from '@ngrx/store';
import { loadAllFeedback} from './feedback.actions';

export interface State {
  feedback: any[];
  totalCount: number;
  offset: number;
}

export const initialState: State = {
  feedback: [],
  totalCount: 0,
  offset: 0,
};

export function reducer(state = initialState, action: any): State {
  switch (action.type) {
    case loadAllFeedback.success.type:
      if (!action.feedback) {
        console.error('Action feedback is undefined');
      }
      const newState = {
        ...state,
        feedback: action.feedback,
        offset: action.offset,
        totalCount: action.totalcount,
      };
      return newState;
    default:
      return state;
  }
}

export const getFeedback= (state: State) => state.feedback;
export const getTotalCount = (state: State) => state.totalCount;
export const getOffset = (state: State) => state.offset;