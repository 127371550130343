import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { loadPolicySettings } from '@app/policy-editor/policy-editor-settings.actions';
import { skip, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as reducers from '@app/reducers';

@Component({
  selector: 'app-custom-settings',
  templateUrl: './custom-settings.component.html',
  styleUrls: ['./custom-settings.component.scss'],
})
export class CustomPolicySettingsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentPolicyName = '';
  // policyId: '';
  settings: any = [];
  constructor(
    private route: ActivatedRoute,
    private store: Store<reducers.State>
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.currentPolicyName = params['customPolicy'];
      this.store.dispatch(
        loadPolicySettings.init({ policy: this.currentPolicyName })
      );
    });
    this.store
      .select(reducers.getPolicySettings)
      .pipe(takeUntil(this.destroy$), skip(1))
      .subscribe((settings: any) => {
        if (settings) {
          this.settings = settings;
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
