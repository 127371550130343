<section>
  <div class="devices-header top-section actions-container">
    <h2>{{ poilicyName }}</h2>
  </div>
  <div class="add-user-form columns-12 box form-card">
    <form [formGroup]="policyForm">
      <div class="textarea" *ngFor="let policy of settings; let i = index">
        <div class="form-group" *ngIf="policy.type === 'textarea'">
          <label class="label-text" for="policy">{{ settings[i].title }}</label>
          <textarea
            type="text"
            formControlName="policy"
            [cols]="settings[i].validation.cols"
            [rows]="settings[i].validation.rows"
            [formControlName]="settings[i].controlName"
            class="form-control"
            id="policy"
            aria-describedby="policyHelp"
            placeholder="Enter policy"
          ></textarea>
          <div class="globalSubtext">
            {{ settings[i].description }}
          </div>
        </div>
        <div class="group">
          <ul class="settingslist" *ngIf="policy.type === 'checkbox'">
            <li class="card cards">
              <div class="card-body">
                <div class="card-content">
                  <div class="columns-9 -policyEditorGrid-8 dark-title-text">
                    {{ settings[i].title }}
                    <div class="globalSubtext">
                      {{ settings[i].description }}
                    </div>
                  </div>
                  <div class="columns-2 -policyEditorGrid-3 globaltoggle">
                    <label class="switch-lg">
                      <input
                        type="checkbox"
                        [disabled]="settings[i].disabled"
                        [formControlName]="settings[i].controlName"
                        id="forceLogins"
                      />
                      <div class="slider-lg round-toggle-lg"></div>
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="group" *ngIf="policy.type === 'radio'">
            <div class="columns-9 -policyEditorGrid-8 dark-title-text">
              {{ settings[i].title }}
              <div class="globalSubtext">
                {{ settings[i].description }}
              </div>
            </div>
            <mat-radio-group
              [formControlName]="settings[i].controlName"
              [disabled]="settings[i].disabled"
              aria-label="Select an option"
            >
              <mat-radio-button
                [value]="option"
                *ngFor="let option of policy.optionsList; let index = index"
                >{{ option }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="submit-section">
        <button
          type="submit"
          (click)="onSubmit()"
          class="button small -primary customBtn mb-1"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</section>
